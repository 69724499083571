import { useSelector } from "react-redux";
import toTitleCase from "../utils/toTitleCase";
import PathwayStatCard from "./PathwayStatCard";
import getNoiseVariance from "../utils/getNoiseVariance";
import { useState } from "react";
import { Button } from "@mui/material";

export default function Pathway({docs}) {
  //let docs = useSelector((state) => state.data.docs);
  const all_p_tith = useSelector(state => state.data.p_tith);
  const cancerFilters = useSelector((state) => state.data.currentFilters.cancers);
  const excluded_genes = useSelector((state) => state.data.p_tith_excluded);
  const excluded_unique = [...new Set(excluded_genes)];

  let p_tith = {}
  cancerFilters.forEach(cancer => {p_tith[cancer.toLowerCase()] = all_p_tith[cancer.toLowerCase()]});
  const [seeExcluded, setSeeExcluded] = useState(false);

  const noise_variance = getNoiseVariance(docs);

  return (
    <div>
      {/* component heading */}
      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
        Pathway Gene Analysis
      </h3>

      {/* cards container */}
      <div className="pathway cancer-sites-container">
        {Object.entries(p_tith).map(([cancerSite, stats]) => (
          <PathwayStatCard cancerSite={cancerSite} p_tith={stats} nv={{'normal':noise_variance[cancerSite+' normal'], 'tumor' : noise_variance[cancerSite+' tumor']}}  />
        ))}
      </div>
      <div style={{padding: '0 20px', alignContent:'center'}}>
      {excluded_unique.length > 0 && <Button onClick={() => setSeeExcluded(s => !s)} > {seeExcluded ? "Hide Excluded Genes" : "See Excluded Genes"}</Button>}      
      {seeExcluded && <p>The following genes were excluded from p_tith calculation due to no interacting partners or ambiguity of multiple transcripts: <br />{excluded_unique.join(", ")}</p>}
      </div>
    </div>
  );
}
