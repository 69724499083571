import React from "react";
import Plot from "react-plotly.js";
import toTitleCase from "../utils/toTitleCase";

export default function HorizontalBarChart({ data, title, xlabel, ylabel }) {
  const categories = Object.keys(data).map(t => toTitleCase(t));
  const values = Object.values(data);
  const plotTitle = title ? title : "Differential Noise";
  const xtitle = xlabel ? xlabel : "Value";
  const ytitle = ylabel ? ylabel : "Site";

  return (
    <Plot
      data={[
        {
          x: values,
          y: categories,
          type: "bar",
          orientation: "h",
          marker: {
            color: values.map((value) => (value < 0 ? "red" : "blue")),
          },
        },
      ]}
      layout={{
        title: {
          text: plotTitle,
          font: {
            size: 15,
            weight: 'bold'
          }},
        xaxis: {
        title: {
          text: xtitle,
          font: {
            size: 15,
            weight: 'bold'
          }}},
        yaxis: { 
          title: {
          text: ytitle,
          font: {
            size: 15,
            weight: 'bold'
          }},
          tickfont: {
            weight: "bold",
          },
          automargin: true,},
        bargap: 0.2,
      }}
      config={{ responsive: true }}
    />
  );
}
