import { useState } from "react";
import IndepCancerSelect from "./IndepCancerSelect";
import Tooltip from '@mui/material/Tooltip';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css'; 
import { Typography } from "@mui/material";
import { InlineMath } from 'react-katex';

const contColStyle = {flexDirection: 'column', gap:'30px'};
const setColStyle = { flexDirection: 'column', justifyContent: 'left', alignItems: 'start'};
const titleColStyle = {width:'100%', textAlign: 'left', fontSize: '0.95rem', color: 'black',   borderBottom: '1px solid #007BFF', marginBottom:'5px'};
const optionsColStyle =  {flexDirection: 'column', gap: '8px', alignItems: 'start', justifyContent: 'left', fontSize: '0.87rem'};

function ResultOptionsBar({unit, setUnit, noise, setNoise, flex}){
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }; 
    const cvs = <Typography variant="body2">
                    <InlineMath math={'\\text{CV}^2 = \\left(\\frac{\\sigma}{\\mu}\\right)^2'} />
                </Typography>;

    const cv = <Typography variant="body2">
                    <InlineMath math={'\\text{CV} = \\frac{\\sigma}{\\mu}'} />
                </Typography>

    return (
        <div className="display-options-container" style={flex=='col' ? contColStyle : {}} >
            <div className="options-set" style={flex=='col' ? setColStyle : {}}>
                <p className='option-title' style={flex=='col' ? titleColStyle : {}}> Expression Normalization Method </p>
                <div className="options" style={flex=='col' ? optionsColStyle : {}}>
                <Tooltip title="Transcripts Per Million" placement="right-start" ><label><input id="tpm" type="radio" name="unit" value="tpm" checked={unit=="tpm"} onChange={()=>setUnit("tpm")} /> TPM</label></Tooltip>
                <Tooltip title="Fragments Per Kilobase of transcript per Million mapped reads" placement="right-start" ><label><input id="fpkm" type="radio" name="unit" value="fpkm" checked={unit=="fpkm"} onChange={()=>setUnit("fpkm")} /> FPKM</label></Tooltip>
                <Tooltip title="Fragments Per Kilobase of transcript per Million mapped reads upper quartile" placement="right-start"><label><input id="fpkm_uq" type="radio" name="unit" value="fpkm_uq" checked={unit=="fpkm_uq"} onChange={()=>setUnit("fpkm_uq")} /> FPKM_UQ</label></Tooltip>
                </div>
            </div>
            <div className="options-set" style={flex=='col' ? setColStyle : {}}>
                <p className='option-title' style={flex=='col' ? titleColStyle : {}} > Noise Metric </p>
                <div className="options" style={flex=='col' ? optionsColStyle : {}}>
                <Tooltip title={cv} placement="right-start" ><label><input id="cv" type="radio" name="noise-metric" value="cv" checked={noise=="cv"} onChange={()=>setNoise("cv")} /> CV</label></Tooltip>
                <Tooltip title={cvs} placement="right-start" ><label><input id="cvs" type="radio" name="noise-metric" value="cvs" checked={noise=="cvs"} onChange={()=>setNoise("cvs")} />{' CV\u00b2'}</label></Tooltip>
                </div>
            </div>
            
        </div>
    )

}

export default ResultOptionsBar;