import { useDispatch } from "react-redux";
import CancerSelection from "../components/v2/CancerSelection";
import { cancers } from "../data/cancers";
import { useState } from "react";
import { applyCancerFilter } from "../store/dataSlice";
import Header from "../components/Header";
import TabsBar from "../components/v2/TabsBar";
import { useNavigate } from 'react-router-dom';
import GeneSelectionMain from "../components/v2/GeneSelectionMain";
import GeneSelectDetail from "../components/v2/GeneSelectDetail";
import Cookies from 'js-cookie';
import Footer from "../components/v2/Footer";

const pages = ['enrich', 'nearest-neighbor', 'db', 'gene-file'];

function QueryPage() {
    let initCancerCheck = {};
    const cancerCookie = Cookies.get('cancers');
    cancers.forEach(cancer => {initCancerCheck[cancer] = false});
    if (cancerCookie)
        cancerCookie.split(',').forEach(c => {initCancerCheck[c] = true});
    const dispatch = useDispatch();
    const [cancerStatus, setCancerStatus] = useState(initCancerCheck);
    const [err, setErr] = useState("");
    const [genePane, setGenePane] = useState("");
    const navigate = useNavigate();  
    let selectedCancers = [];
    for (let key in cancerStatus)
        if (cancerStatus[key] == true)
            selectedCancers.push(key);
            

    return (
        <div className="query-page">
        <Header />
        <TabsBar tab="Query" />
        <div className="query-grid">
            <div className="query-header" >
                <p>Pathway Analysis Dashboard</p>
            </div>
            <div className="selection">
                <CancerSelection selectedCancers={cancerStatus} setSelectCancers={setCancerStatus} />
                <div className="analysis-options-container">
                {genePane == "" ? <GeneSelectionMain showGeneQuery={setGenePane} /> : <GeneSelectDetail option={genePane} cancers={selectedCancers} back={() => setGenePane("")} />}
                </div>
            </div>
        </div>
        <Footer />
        </div>
    )
}

export default QueryPage;