import React from "react";
import { useSelector } from "react-redux";
import { create, all } from "mathjs";
import generateHeatmapData from "../utils/generateHeatmapData";
import getDisplayField from "../utils/getDisplayField";
import { useState } from "react";
import ResultOptionsBar from "../components/v2/ResultOptionsBar";
import Header from "../components/Header";
import ResultsTable from "../components/ResultsTable";
import HorizontalBarChart from "../components/HorizontalBarChart";
import ResultsFooter from "../components/v2/ResultsFooter";
import Heatmap from "../components/Heatmap";
import TranscriptSelect from "../components/TranscriptSelect";
import GeneTable from "../components/GeneTable";
import generateCSVData from "../utils/generateCSVData";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import IndepCancerSelect from "../components/v2/IndepCancerSelect";
import toTitleCase from "../utils/toTitleCase";

const stickyColSX = {
  position: "sticky",
  left: 0,
  fontWeight: 'bold',
  color: '#007bff',
};

const GeneAnalysisResult = () => {
  const math = create(all, {});
  let geneNames = useSelector((state) => state.data.currentFilters.geneNames);
  let geneIDs = useSelector((state) => state.data.currentFilters.geneIDs);
  let docs = useSelector((state) => state.data.docs);
  let queryType = useSelector((state) => state.data.geneQueryType);
  let queryContent = useSelector((state) => state.data.geneQueryContent);
  let transcripts = [...geneIDs];
  //transcripts = transcripts.map(id => id.replace(/-/g, '.'));
  let cancerFilters = useSelector(state => state.data.currentFilters.cancers);

  let filterDocNames = cancerFilters.map((c) => c.toLowerCase());
  if (docs && filterDocNames.length != 0)
    docs = docs.filter((doc) =>
      filterDocNames.includes(doc.cancer_type.split(" ")[0])
    );

  const [unit, setUnit] = useState("tpm");
  const [noiseMethod, setNoiseMethod] = useState("cv");
  const [displayTranscripts, setDisplayedTranscripts] = useState(transcripts);
  const [showAverage, setShowAverage] = useState(geneNames.length > 1 ? true : false);

  let displayFieldNoise = getDisplayField("noise", unit);
  let displayFieldExp = getDisplayField("exp", unit);
  let displayGeneNames = displayTranscripts.map(() => geneNames[0]);


  console.log("geneIDs", geneIDs);
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  let initdataMatrixNoise = generateHeatmapData(docs, displayFieldNoise, transcripts, noiseMethod, showAverage);
  let initdataMatrixExp = generateHeatmapData(docs, displayFieldExp, transcripts, "",  showAverage);
  // Get indices of transcripts
  const indices = transcripts
    .map((value, index) => displayTranscripts.includes(value) ? index : -1)
    .filter(index => index !== -1)
    .sort((a, b) => a - b); 
  
  if (showAverage)
      indices.push(transcripts.length);

  let dataMatrixExp = indices.map(index => initdataMatrixExp[index]);
  let dataMatrixNoise = indices.map(index => initdataMatrixNoise[index]);

  console.log("matrix: ", dataMatrixNoise)
  // tables

  const initcsvDataExp = generateCSVData(displayFieldExp, docs, transcripts, displayGeneNames, "", true);
  const initcsvDataNoise = generateCSVData(displayFieldNoise, docs, transcripts, displayGeneNames, noiseMethod, true);
  indices.unshift(0);
  let csvDataExp = indices.map(index => initcsvDataExp[index+1]);
  let csvDataNoise = indices.map(index => initcsvDataNoise[index+1]);

  let cancers = docs.map((doc) => doc.cancer_type);

  // if (geneNames.length > 1) {
  //   console.log("slicing geneNames");
  //   geneNames = geneNames.slice(1);
  //   transcripts.push('Average')
  // }

  const handleTranscriptSelect = (transcripts) => {
    const transcriptIDs = transcripts.filter(item => item !== 'Average');
    setDisplayedTranscripts(transcriptIDs);
    if (transcripts.includes('Average'))
      setShowAverage(true);
    else
      setShowAverage(false);
  }

  console.log("showavg: ", showAverage)
  let heatmapGeneLabels = [...displayTranscripts];
  if (showAverage)
      heatmapGeneLabels.push('Average');

  let allTranscripts = [...geneIDs];
  if (allTranscripts.length > 1)
      allTranscripts.push('Average');

  return (
    <div className="page">
      <Header />
      <div
        className="query-header"
        style={{ padding: "10px", paddingLeft: "20px" }}
      >
        <p> Gene Analysis Dashboard </p>
      </div>
      <div className="result-page-body">
        <div className="filter-panel-container results">
          <div className="query-info">
            <p className="query-type">Gene Query</p>
            {queryType !== "Enrichment Analysis" ? (
              queryType != "Database ID" ? (
                <p className="query-content">
                  {showFullText
                    ? queryContent
                    : `${queryContent.slice(0, 36)}${
                        queryContent.length > 36 ? "..." : ""
                      }`}
                  {queryContent.length > 36 && (
                    <span className="more-text" onClick={toggleText}>
                      {showFullText ? " Show Less" : " Show More"}
                    </span>
                  )}
                </p>
              ) : (
                <p className="query-content">{queryContent}</p>
              )
            ) : (
              <>
                <span className="query-term">
                  <span>{queryContent.term}</span>
                  <span className="category">[{queryContent.category}]</span>
                </span>
                <span className="query-desc">{queryContent.description}</span>
              </>
            )}
          </div>

          <ResultOptionsBar
            flex="col"
            unit={unit}
            setUnit={setUnit}
            noise={noiseMethod}
            setNoise={setNoiseMethod}
          />
         <IndepCancerSelect notPopup={true} />
        <TranscriptSelect transcripts={allTranscripts} onApply={handleTranscriptSelect} />
        </div>

        {geneNames.length > 0 ? (
          <div className="data">
            <div className="gene charts">
              <Heatmap
                displayField={displayFieldExp}
                cancers={cancers}
                genes={heatmapGeneLabels}
                dataMatrix={dataMatrixExp}
                geneFlag={true}
              />
              <Heatmap
                displayField={displayFieldNoise}
                cancers={cancers}
                genes={heatmapGeneLabels}
                dataMatrix={dataMatrixNoise}
                noiseMethod={noiseMethod}
                geneFlag={true}
              />
            </div>
            {/* {geneIDs.map((geneID, index) => (
              <div>
                <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                  Heatmap for gene {geneNames[0]} annotation {geneID}{" "}
                </h4>
                <div className="charts">
                  <Heatmap
                    displayField={displayFieldExp}
                    cancers={cancers}
                    genes={geneNames}
                    dataMatrix={generateHeatmapData(
                      docs,
                      displayFieldExp,
                      [geneID],
                      ""
                    )}
                  />
                  <Heatmap
                    displayField={displayFieldNoise}
                    cancers={cancers}
                    genes={geneNames}
                    dataMatrix={generateHeatmapData(
                      docs,
                      displayFieldNoise,
                      [geneID],
                      noiseMethod
                    )}
                    noiseMethod={noiseMethod}
                  />
                </div>
              </div>
            ))} */}

            <div className="table-container">
              <GeneTable csvData={csvDataExp}  title={"Mean Expression "+ '(' + displayFieldExp.split('_').slice(1).join('_').toUpperCase() + ')'} sites={cancers} caption={displayFieldExp} />
              <GeneTable csvData={csvDataNoise}  title={"Noise "+ '(' + displayFieldNoise.split('_').slice(1).join('_').toUpperCase() + ')'} sites={cancers} caption={displayFieldNoise} />
              {/* <ResultsTable displayField={displayFieldExp} noiseMethod="" geneFlag={true} />
              <ResultsTable displayField={displayFieldNoise} noiseMethod={noiseMethod} geneFlag={true} /> */}
            </div>

            {geneIDs.map((geneID, index) => {
              let diffNoiseData = {};

              docs.map((doc, i) => {
                let site = docs[i].cancer_type.split(" ")[0];

                if (i % 2 === 0) {
                  let normal = docs[i][displayFieldNoise][geneID].value;
                  let tumor = docs[i + 1][displayFieldNoise][geneID].value;
                  let diffNoise = math.log2(tumor / normal);
                  diffNoiseData[site] = diffNoise;
                }
              });

              // console.log("diffNoiseData here", diffNoiseData);
              let abs_vals = Object.values(diffNoiseData).map((val) =>
                math.abs(val)
              );

              abs_vals = abs_vals.sort().reverse();

              let topSites = {};

              for (let i = 0; i < abs_vals.length; i++) {
                for (let [key, value] of Object.entries(diffNoiseData)) {
                  if (value < 0) {
                    if (math.abs(value) === abs_vals[i]) {
                      topSites[key] = value;
                    }
                  } else {
                    if (value === abs_vals[i]) {
                      topSites[key] = value;
                    }
                  }
                }
              }
              let diffCSV = Object.entries(topSites);
              diffCSV.unshift(['Site', 'logFC']);
            

              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Gene {geneNames[0]} Annotation {geneID}
                  </span>
                  <HorizontalBarChart data={topSites} xlabel={'logFC'} />
                  <div>
                  <TableContainer sx={{ maxHeight: 440, width: '100%', overflowX: 'auto',  margin: '20px auto', border: '1px solid silver', display: 'block'}}>
                  <Table stickyHeader aria-label="sticky table">
                    <caption>Differential Noise {geneID}</caption>
                    <TableHead>
                      <TableRow>
                      <TableCell colSpan={3}  sx={{textAlign: 'left !important', ...stickyColSX , zIndex: '3'}}>
                            <button className="analysis-button download"><CSVLink data={diffCSV} filename={'diff_noise_' + geneID + '.csv'} className="link"><DownloadIcon /></CSVLink></button>
                            <span style={{paddingLeft: '20px'}}>Differential Noise [{geneID}]</span>
                        </TableCell>
                        <TableCell colSpan={2}></TableCell>
                      </TableRow>
                      <TableRow>
                        {diffCSV[0].map((column, i) => (
                          <TableCell
                            key={column}
                            align='center'
                            style={{ top: 57, minWidth: '100px' }} 
                            sx={i == 0 ? {...stickyColSX, backgroundColor: 'lightblue', zIndex: '3'} : null}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {diffCSV.slice(1).map((row, j) => {
                        return(
                            <TableRow hover role="checkbox" tabIndex={-1} key={j}>
                              <TableCell sx={{...stickyColSX,minWidth: '60px', borderBottom: '1px solid #d0d0d0', zIndex:2}} className="genecell" >{toTitleCase(row[0])}</TableCell>
                              {row.slice(1).map((val, k) => 
                                <TableCell key={k}>{val.toFixed(2)}</TableCell> // Display with 5 decimal
                              )}
                            </TableRow>
                        )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h4 className="no-results">No Results Found for Input.</h4>
        )}
      </div>
      <ResultsFooter />
    </div>
  );
};

export default GeneAnalysisResult;
