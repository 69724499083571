import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import toTitleCase from "../utils/toTitleCase";
import {useSelector} from "react-redux";
import { CSVLink } from "react-csv";
import generateCSVData from "../utils/generateCSVData";
import DownloadIcon from '@mui/icons-material/Download';
import { blue } from "@mui/material/colors";
import generateCSV_TH from "../utils/generateCSV_TH";

const stickyColSX = {
      position: "sticky",
      left: 0,
      fontWeight: 'bold',
      color: '#007bff',
    };

export default function ThTable ({depth_json, site}) {
    const csvData = generateCSV_TH(depth_json);
    const title = 'DEPTH2 Scores'
    console.log(csvData);

    return (
        <TableContainer sx={{ maxHeight: 440, width: '100%', overflowX: 'auto',  margin: '0 auto', border: '1px solid silver', display: 'block'}}>
           <Table stickyHeader aria-label="sticky table">
             <caption>DEPTH2 Scores</caption>
             <TableHead>
               <TableRow>
               <TableCell colSpan={3}  sx={{textAlign: 'left !important', ...stickyColSX , zIndex: '3'}}>
                     <button className="analysis-button download"><CSVLink data={csvData} filename={'depth_' + site + '.csv'} className="link"><DownloadIcon /></CSVLink></button>
                     <span style={{paddingLeft: '20px'}}>{title}</span>
                 </TableCell>
                 <TableCell colSpan={2}></TableCell>
               </TableRow>
               <TableRow>
                 {csvData[0].map((column, i) => (
                   <TableCell
                    key={column}
                    align='center'
                    style={{ top: 57, minWidth: column.minWidth }} 
                    sx={i == 0 ? {...stickyColSX, backgroundColor: 'lightblue', zIndex: '3'} : null}
                  >
                    {column}
                  </TableCell>
                 ))}
               </TableRow>
             </TableHead>
             <TableBody>
               {csvData.slice(1).map((row, j) => {
                return(
                     <TableRow hover role="checkbox" tabIndex={-1} key={j}>
                       <TableCell sx={{...stickyColSX,  borderBottom: '1px solid #d0d0d0', zIndex:2}} className="genecell" >{row[0]}</TableCell>
                       {row.slice(1).map((val, k) => 
                        <TableCell key={k}>{val.toFixed(5)}</TableCell> // Display with 5 decimal
                       )}
                     </TableRow>
                )
                 })}
             </TableBody>
           </Table>
         </TableContainer>
    );
}