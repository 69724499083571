import React, { useState, useEffect } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";

function ResultsFooter() {
  const [isVisible, setIsVisible] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);

  const handleScroll = () => {
    if (!isScrollingUp) {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 500);
    }
  };

  const scrollToTop = () => {
    setIsScrollingUp(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      setIsScrollingUp(false);
      setIsVisible(false);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingUp]);

  return (
    <div className="results-footer">
      <div className="last">
        <span>
          © 2024 Biomedical Informatics and Engineering Research Lab (BIRL),
          Lahore University of Management Sciences (LUMS)
        </span>
      </div>
      {isVisible && (
        <Button
          variant="contained"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "0px",
            paddingRight: "0px",
            borderRadius: "10px",
            zIndex: 1000,
            opacity: 0.8,
            transition: "opacity 0.1s",
          }}
        >
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </div>
  );
}

export default ResultsFooter;
