function Footer() {
	return (
		<footer className="footer">
			<div className="footer__main">
				<div>
					This website is free and open to all users and there is no login requirement. For more information, please contact us.
				</div>
				<div >
					<a href="https://birl.lums.edu.pk/" target="_blank">
						© 2024 Biomedical Informatics & Engineering Research Laboratory (BIRL)
					</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer;