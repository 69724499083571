import GNenrich from '../../pages/GNEnrich';
import GNNeighbor from '../../pages/GNNeighbor';
import GNdb from '../../pages/GNdb';
import GFile from '../../pages/GFile';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

function GeneSelectDetail ({option, back, cancers, ref}) {
    return (    
    <>
        {option === 'enrich' && <GNenrich cancers={cancers} ref={ref} />}
        {option === 'neighbors' && <GNNeighbor cancers={cancers} ref={ref} />}
        {option === 'db' && <GNdb cancers={cancers}  ref={ref}/>}
        {option === 'file' && <GFile cancers={cancers}  ref={ref}/>}
        <button onClick={back} className='back'><ArrowBackIosRoundedIcon fontSize='small' /></button>
    </>
    );
}


export default GeneSelectDetail;