import { useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const normalButtonStyle = {fontSize: '0.95rem', height: '2rem', width: '5rem'}

function TranscriptSelect({transcripts, onApply}){
    let currSelectedtranscripts = transcripts;

    let init_transcriptCheck = {};
    transcripts.forEach(transcript => {init_transcriptCheck[transcript] = false});
    currSelectedtranscripts.forEach(transcript => init_transcriptCheck[transcript] = true);
    const [selectedtranscripts, setSelectedtranscripts] = useState(init_transcriptCheck);
    const [err, setErr] = useState("");
    const dispatch = useDispatch();
    const [select, setSelect] = useState(true);

    //console.log("selected transcripts: ", selectedtranscripts);

    const handleToggle = () => {
        if (select)
            handleSelecttranscript("all");
        else
            handleSelecttranscript("none");
        setSelect(s => !s);
    }

    const handleApply = (i) => {
        let filters = [];
        for (let key in selectedtranscripts)
            if (selectedtranscripts[key] == true){
                filters.push(key)
            }
        if (filters.length > 0){
            setErr("")
            //dispatch(applytranscriptFilter(filters));
            if (onApply)
                onApply(filters);
        }
        else
            setErr("No Trancripts Selected.")
    }

    const handleSelecttranscript = (option) => {
        let newSelection;
        if (option == "all"){
            newSelection = {...selectedtranscripts};
            for (let transcript in selectedtranscripts)
                newSelection[transcript] = true;
        }
        else if (option == "none"){
            newSelection = {...selectedtranscripts};
            for (let transcript in selectedtranscripts)
                newSelection[transcript] = false;
        }
        else {
            newSelection = {
                ...selectedtranscripts,
                [option]: !selectedtranscripts[option],
            };
        }
        //console.log('option: ', option);
        //console.log("new selection: ", newSelection);
        setSelectedtranscripts(newSelection);
        if (err)
            setErr("");
    };

    return (
        <div className="filter-panel-container popup" style={{paddingLeft: '0'}}>
            <div className="filter-group" style={{width:'100%'}}>
            <div className="filter-header">
                    <h3 className="selection-title" style={{fontSize: '0.95rem'}} > Isoforms </h3>
            </div>
            <div className="filter-buttons">
                    <button onClick={() => handleSelecttranscript("all")}> Select All</button>
                    <button onClick={() => handleSelecttranscript("none")}> Deselect All</button>
                </div>
                <div className="filter-options-container">
                {transcripts.map(transcript => 
                <label key={transcript} htmlFor={transcript+"transcript"} className={selectedtranscripts[transcript] ? "checked" : ""} ><input id={transcript+"transcript"} type="checkbox" onChange={() => handleSelecttranscript(transcript)} checked={selectedtranscripts[transcript]} /> {transcript}</label>
                )}
                </div>
            </div>
            <button className="analysis-button apply" onClick={handleApply} style={normalButtonStyle} >Apply</button>
            {err && <p style={{color:"red", margin: '0 auto'}}>{err}</p>}
        </div>
    )
}

export default TranscriptSelect;