import Plot from "react-plotly.js";

export default function NoiseScatterPlot() {
  const cancerSites = ["Brain", "Lung", "Pancreas"];
  const normalNoiseValues = [10, 15, 8];
  const tumorNoiseValues = [20, 25, 18];
  const normalNoiseValues2 = [12, 14, 10];
  const tumorNoiseValues2 = [22, 22, 19];
  const normalNoiseValues3 = [13, 16, 11];
  const tumorNoiseValues3 = [24, 26, 20];

  // const cancerSites = Object.keys(data);

  // let plotData = [] 
  // Object.entries(data).forEach(([site, siteData]) => {
  //   let plot = {
  //     x: cancerSites,
  //     y: siteData.normal,
  //     mode: "markers",
  //     name: "Normal Noise",
  //     marker: {
  //       color: "#1f77b4",
  //       symbol: "circle",
  //       size: 12,
  //       line: { color: "black", width: 1 },
  //     },
  //   };
  //   plotData.push(plot);

  //   plot = {
  //     x: cancerSites,
  //     y: siteData.tumor,
  //     text: siteData.genes,
  //     mode: "markers",
  //     name: "Tumor Noise",
  //     marker: {
  //       color: "#d62728",
  //       symbol: "x",
  //       size: 12,
  //       line: { color: "black", width: 1 },
  //     },
  //   };
  //   plotData.push(plot);

  // }) 
  return (
    <div>
      <Plot
        data={[
          // normalNoiseValues: blue dots
          {
            x: cancerSites,
            y: normalNoiseValues,
            mode: "markers",
            name: "Normal Noise",
            marker: {
              color: "#1f77b4",
              symbol: "circle",
              size: 12,
              line: { color: "black", width: 1 },
            },
          },
          // // tumorNoiseValues: red cross
          {
            x: cancerSites,
            y: tumorNoiseValues,
            mode: "markers",
            name: "Tumor Noise",
            marker: {
              color: "#d62728",
              symbol: "x",
              size: 12,
              line: { color: "black", width: 1 },
            },
          },
          {
            x: cancerSites,
            y: normalNoiseValues2,
            mode: "markers",
            name: "Normal Noise",
            marker: {
              color: "#1f77b4",
              symbol: "circle",
              size: 12,
              line: { color: "black", width: 1 },
            },
          },
          // // tumorNoiseValues: red cross
          {
            x: cancerSites,
            y: tumorNoiseValues2,
            mode: "markers",
            name: "Tumor Noise",
            marker: {
              color: "#d62728",
              symbol: "x",
              size: 12,
              line: { color: "black", width: 1 },
            },
          },
          {
            x: cancerSites,
            y: normalNoiseValues3,
            mode: "markers",
            name: "Normal Noise",
            marker: {
              color: "#1f77b4",
              symbol: "circle",
              size: 12,
              line: { color: "black", width: 1 },
            },
          },
          // // tumorNoiseValues: red cross
          {
            x: cancerSites,
            y: tumorNoiseValues3,
            mode: "markers",
            name: "Tumor Noise",
            marker: {
              color: "#d62728",
              symbol: "x",
              size: 12,
              line: { color: "black", width: 1 },
            },
          },
        ]}
        layout={{
          title: {
            text: "Noise Scatter Plot",
            font: { size: 20, family: "Arial, sans-serif", color: "#4a4a4a" },
          },
          xaxis: {
            title: {
              text: "Cancer Sites",
              font: {
                size: 16,
                family: "Arial, sans-serif",
                color: "rgb(20, 20, 20)",
              },
            },
            showgrid: true,
            gridcolor: "rgb(210,210,210)",
            zeroline: false,
          },
          yaxis: {
            title: {
              text: "Noise Value",
              font: {
                size: 16,
                family: "Arial, sans-serif",
                color: "rgb(20, 20, 20)",
              },
            },
            showgrid: true,
            gridcolor: "#e6e6e6",
            zeroline: false,
          },
          plot_bgcolor: "rgb(240, 240, 240)",
          paper_bgcolor: "rgb(240, 240, 240)",
          showlegend: true,
          legend: {
            font: { size: 14, family: "Arial, sans-serif" },
            bgcolor: "#f8f8f8",
            bordercolor: "#e6e6e6",
            borderwidth: 1,
          },
        }}
      />
    </div>
  );
}
