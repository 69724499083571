import { useState } from "react";
import { useNavigate } from "react-router-dom";
import geneNames from "../data/geneNames.json";
import Header from "../components/Header";
import Footer from "../components/v2/Footer";
import { useDispatch } from "react-redux";
import { applyCancerFilter, saveFilterData } from "../store/dataSlice";
import { cancers } from "../data/cancers";

const genes_data = geneNames["geneNames"];
const genes = [...new Set(genes_data)];

const GeneAnalysis = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGenes, setFilteredGenes] = useState([]);
  const [isFocused, setFocused] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    const matches = genes.filter((gene) =>
      gene.toLowerCase().startsWith(query.toLowerCase())
    );

    setFilteredGenes(matches.slice(0, 20));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!document.querySelector(".suggestions:hover")) {
        setFocused(false);
      }
    }, 100);
  };

  const handleNavigation = (geneName) => {
    if (geneName === "") navigate("/");
    else {
      let url = "https://tcgna.cancerlogics.com/pathway?genes=";
      //let url = "http://localhost:8000/pathway?genes=";
      url += geneName + "&genes=x";

      fetch(url)
        .then((data) => data.json())
        .then((json) => {
          const { geneNames, geneIDs, filterDocs, notFoundGenes, p_tith } = json;
          const currentFilters = {
            geneIDs,
            geneNames,
          };

          // dispatch(applyCancerFilter(cancers));

          dispatch(
            saveFilterData({
              filterDocs,
              currentFilters,
              p_tith,
              notFoundGenes,
              queryType: "Gene Analysis",
              queryContent: geneName,
            })
          );
          dispatch(applyCancerFilter(cancers));


          navigate("/gene-analysis-results");
        });
    }
  };

  return (
    <>
      <Header />
      <div className="gene-analysis">
        <div className="box sites-box">
          <div
            className="input-box sites"
            style={{ textAlign: "center", fontSize: ".95rem" }}
          >
            Analyse a selected gene's noise across all sites, in tumor and
            normal states. Currently built on GDC datasets.
          </div>
        </div>
        <div className="gene-analysis__main">
          <h2>Gene Network Selection</h2>
          <h3 className="selection-subtitle">Gene Name</h3>

          <p className="description">
            Search by gene name/symbol. Separate symbols by commas. Select the
            result of interest.
          </p>
          <div>
            <input
              className="gene-analysis__search-box"
              placeholder="Search here e.g: asb5 or dpm1"
              value={searchTerm}
              onChange={handleSearch}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            {searchTerm && filteredGenes.length > 0 && (
              <ul className={`suggestions ${isFocused ? "" : "hide"}`}>
                {filteredGenes.map((gene, index) => (
                  <li
                    style={{ textAlign: "left" }}
                    key={index}
                    className="suggestion-item"
                  >
                    <button onClick={() => handleNavigation(gene)}>
                      {gene}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <button
              onClick={() => handleNavigation("")}
              className="back-button"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GeneAnalysis;
