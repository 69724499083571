import toTitleCase from "../utils/toTitleCase";

export default function PathwayStatCard( {p_tith, nv, cancerSite}) {
    return (
        <>
            <div className="pathway cancer-site-card">
            {/* card header */}
            <h4 className="pathway cancer-site-name">
              {toTitleCase(cancerSite)}
            </h4>

            {/* statistics table */}
            <table className="pathway cancer-site-stats">
              <tbody>
                {Object.entries(p_tith).map(
                  ([statName, statValue]) => {
                    return (
                      <tr key={statName}>
                        <td>pathway-tITH [{statName.substring(7)}]</td>
                        <td>{statValue.toFixed(4)}</td>
                      </tr>
                    );
                  }
                )}
                {Object.entries(nv).map(([type, data]) => {
              return Object.entries(data).map(([unit, statValue]) => {
                return (
                  <tr key={`${type}-${unit}`}>
                    <td>Noise Variance [{type} {unit}]</td>
                    <td>{statValue.toFixed(4)}</td>
                  </tr>
                );
              });
            })}
              </tbody>
            </table>
          </div>  
        </>
    );
}