import { useEffect, useState } from "react";
import { autocomplete_url, specie_suffix } from "../data/urls";

export default function Enrichment ({genes}) {
    let enrichGenesInit = genes.filter(item => 
      !item.includes('.') && !item.includes('-')
    );
    enrichGenesInit = [...enrichGenesInit].sort((a, b) => b.length - a.length); // Sort by length in descending order
    const [enrichGenes, setEnrichGenes] = useState(enrichGenesInit);
    const [enrich, setEnrich] = useState([]);
    console.log("genes: ", genes);
    console.log("enrich genes: ", enrichGenes);
    console.log("enrich: ", enrich);

    useEffect(() => {    
    let url = autocomplete_url + enrichGenes.join("%0d") + specie_suffix;
    let ignore = false;
    fetch(url)
    .then((data) => data.json())
    .then((json) => {
        if (!ignore) {
        let topEntries = [];
        let counter = 0;
        let currentCategory = null;
        console.log(json);

        for (const entry of json) 
          topEntries.push(entry);
          
        
        if (topEntries.length > 0)
          setEnrich(topEntries);
        else{ 
          if (enrichGenes.length > 10)
            setEnrichGenes(enrichGenes.slice(10));
          else
            setEnrich(null);
        }}
    })
    .catch((err) => setEnrich([]));
    
    return () => {
        ignore = true;
    };
    }, [genes]);

    return ( 
        <>
        <div>
        <h5 className="noise-analyse h5">Enrichment</h5>
        <ul className="noise-analyse ul">
          {enrich.length > 0 ? enrich.map((option) => (
            <li
              key={option.term}
              className="noise-analyse enrichment-option"
            >
              <div className="noise-analyse enrichment-term">
                {option.term}&nbsp;&nbsp;&nbsp;&nbsp;[{option.category}]
              </div>
              <div className="noise-analyse enrichment-description">
                {option.description}
              </div>
            </li>
          )) : <p>No enrichment results.</p>}
        </ul>
      </div>
        </>
    );
}