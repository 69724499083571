import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";

const SampleTHPlot = ({ data }) => {
  const site = Object.keys(data)[0];
  const yData = Object.values(data[site]);

  let plotData = [];

  for (let i = 0; i < site.length; i++) {
    plotData.push({
      type: "box",
      y:  Object.values(data[site]),
      name:  site,
      text: Object.keys(data[site]),
      boxpoints: "all",
      jitter: 0.5,
      whiskerwidth: 0.2,
      fillcolor: 'rgba(93, 164, 214, 0.5)',
      marker: { size: 2 },
      line: { width: 1.5,  color: 'rgba(73, 144, 194, 1)'  },
    });
  }
  

  const layout = {
    title: {
      text: "transcriptome-based Intra Tumor Heterogeneity (tITH) For All Patients",
      font: {
        size: 16,
      },
    },
    xaxis: {
      tickangle: -30, // Rotate x-axis labels for readability
    },
    yaxis: {
      title: {
        text: 'tITH',
        font: {
          size: 14,
        },
      },
      autorange: true, // Adjust based on your yData range
      showgrid: true, // Show gridlines
      gridcolor: "rgb(200, 200, 200)",
      zeroline: true,
      zerolinecolor: "rgb(150, 150, 150)",
    },
    margin: {
      l: 50,
      r: 30,
      b: 120,
      t: 100,
    },
    paper_bgcolor: "rgb(243, 243, 243)",
    plot_bgcolor: "rgb(243, 243, 243)",
    showlegend: false,
    width: 700, // Explicit width
    height: 600, // Explicit height
  };

  return (
    <div className="boxenplot">
      <div>
        <Plot data={plotData} layout={layout} />
      </div>
    </div>
  );
};

export default SampleTHPlot;
