import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import generateHeatmapData from "../utils/generateHeatmapData";
import { useEffect, useRef, useState } from "react";
import toTitleCase from "../utils/toTitleCase";

export default function Heatmap({
  cancers,
  genes,
  dataMatrix,
  displayField,
  noiseMethod,
  geneFlag
}) {
console.log("data in heamao: ", genes)
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const cancerLabels = cancers.map((c) => toTitleCase(c));
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setDimensions({
          width:
            window.innerWidth > 1000
              ? (geneFlag ? window.innerWidth * 0.8 : window.innerWidth * 0.35)
              : window.innerWidth * 0.8,
          height: geneFlag ? window.innerHeight * 0.3 : window.innerHeight * 0.6,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = [
    {
      x: cancerLabels,
      y: genes,
      z: dataMatrix,
      type: "heatmap",
      colorscale: "Cividis",
      showscale: true,
      colorbar: {
        len: geneFlag ? 1.5 : 1,
        thickness: 15,
      },
    },
  ];
  const layout = {
    autosize: true,
    title: {
      text:
        (displayField.slice(0, 4) === "mean" ? "Mean Expression " : "Noise ") +
        "(" +
        displayField.split("_").slice(1).join("_").toUpperCase() +
        ")",
        font: {
          weight: "600", // Bold text
        },
    },
    xaxis: { automargin: true, tickfont: { size: 12, weight: "600" }, tickangle: -45 },
    yaxis: { automargin: geneFlag ? true : false, tickfont: { size: 12, weight: "600" } },
  };

  //console.log("data: ", data);

  return (
    <div ref={containerRef} className="heatmap-container">
      {dataMatrix.length > 0 ? (
        <Plot
          data={data}
          layout={layout}
          config={{ responsive: true }}
          style={{ width: dimensions.width, height: dimensions.height }}
        />
      ) : (
        <p>No results found</p>
      )}
    </div>
  );
}
