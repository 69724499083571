export default function getGenesLogFC(normal, tumor, geneIDs, geneNames) {
  let ratios = [];
  let r, r1, r2;
  for (let i = 0; i < geneIDs.length; i++) {
    r1 = Math.log2(tumor[geneIDs[i]].value / normal[geneIDs[i]].value);
    //r2 = Math.log2(normal[geneIDs[i]].value / tumor[geneIDs[i]].value);
    //r = Math.max(r1, r2);
    ratios.push({ id: geneIDs[i], name: geneNames[i], val: r1 });
  }

  ratios = ratios.sort((a, b) => b.val - a.val);
  return ratios;

  //let ratios_thres = ratios.filter((r) => r.val >= threshold);

  //let diff_genes = ratios_thres.map((r) => r.name);
  //let sorted_genes = ratios.map(r => r.name);
  //return {diff_genes, sorted_genes};
}