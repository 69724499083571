import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import toTitleCase from "../utils/toTitleCase";
import {useSelector} from "react-redux";
import { CSVLink } from "react-csv";
import generateCSVData from "../utils/generateCSVData";
import DownloadIcon from '@mui/icons-material/Download';
import { blue } from "@mui/material/colors";

const stickyColSX = {
      position: "sticky",
      left: 0,
      fontWeight: 'bold',
      color: '#007bff',
    };

export default function GeneTable ({csvData, sites, title, caption}) {
    let columns = sites.map( site => { return {
        id: site,
        label: toTitleCase(site),
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(3)
    }});

    columns.unshift({
      id: 'id',
      label: 'GeneID',
      minWidth: 10,
      align: 'left',
  });  


  columns.unshift({
      id: 'gene',
      label: 'Gene',
      minWidth: 10,
      align: 'left',
  });

    return (
        <TableContainer sx={{ maxHeight: 440, width: '100%', overflowX: 'auto',  margin: '0 auto', border: '1px solid silver'}}>
          <Table stickyHeader aria-label="sticky table">
            <caption>{caption}</caption>
            <TableHead>
              <TableRow>
              <TableCell colSpan={columns.length > 3 ? 3 : 2}  sx={{textAlign: 'left !important', ...stickyColSX , zIndex: '3'}}>
                    <button className="analysis-button download"><CSVLink data={csvData} filename={caption + '.csv'} className="link"><DownloadIcon /></CSVLink></button>
                    <span style={{paddingLeft: '20px'}}>{title}</span>
                </TableCell>
                <TableCell colSpan={columns.length > 3 ? columns.length-2 : columns.length-1} ></TableCell>
              </TableRow>
              <TableRow>
              {columns.map((column, i) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }} 
                    sx={i == 0 ? {...stickyColSX, backgroundColor: 'lightblue', zIndex: '3'} : null}
                  >
                    {column.label}
                </TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.slice(1).map((row, rowIndex) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        className={cellIndex == 0 ? "genecell" : ""}
                        align={cellIndex === 0 || cellIndex === 1 ? 'left' : 'center'}
                        sx={cellIndex === 0 ? {...stickyColSX,  borderBottom: '1px solid #d0d0d0', zIndex:2}  : null}
                      >
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
              /* <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }} 
                    sx={i == 0 ? {...stickyColSX, backgroundColor: 'lightblue', zIndex: '3'} : null}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transcripts.map((gene, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={gene}>
                      <TableCell sx={{...stickyColSX,  borderBottom: '1px solid #d0d0d0', zIndex:2}} className="genecell" >{geneNames[i]}</TableCell>
                      {geneFlag && <TableCell sx={{...stickyColSX,  borderBottom: '1px solid #d0d0d0', zIndex:2}} className="genecell" >{transcripts[i]}</TableCell> }
                      {docs.map((doc, j) => {
                        const value = noiseMethod == 'cvs' ? Math.pow(doc[displayField][gene].value,2).toFixed(3) : doc[displayField][gene].value.toFixed(3);
                        return (
                          <TableCell key={columns[j].id} align='center'>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer> */
    );
}