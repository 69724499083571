import React from "react";
import { useLocation } from "react-router-dom";
import Plot from "react-plotly.js";
import Header from "../components/Header";
import ResultsFooter from "../components/v2/ResultsFooter";
import geneNames from "../data/geneNames.json";
import { useSelector } from "react-redux";
import ThTable from "../components/ThTable";
import TumorNoisyGenes from "../components/TumorNoisyGenes";
import SampleTHPlot from "../components/SampleTHPlot";

const TumorHeterogeneityResults = () => {
  const location = useLocation();
  const { cancerSite } = location.state || {};
  const genes = geneNames["geneNames"];
  const depth_scores = useSelector((state) => state.data.data.th_data.depth2);
  const samples_th = useSelector((state) => state.data.data.th_data.sample_th);
  const noisy_genes_data = useSelector((state) => state.data.data.noisy_gene_data);
  console.log("depth_scores: ", depth_scores);

  let fields = Object.keys(depth_scores)
  let data = [];
  for (var i = 1; i < 4; i++) {
    var result = {
      type: "box",
      y: depth_scores[fields[i]],
      name: fields[i],
      text: depth_scores.Sample,
      boxpoints: "all",
      jitter: 0.5,
      whiskerwidth: 0.2,
      fillcolor: "cls",
      marker: {
        size: 2,
      },
      line: {
        width: 1.5,
      },
    };
    data.push(result);
  }
  
  var layout = {
    title: {
      text: "DEPTH2",
    },
    yaxis: {
      autorange: true,
      showgrid: true,
      zeroline: true,
      dtick: 1,
      gridcolor: "rgb(185, 182, 182)",
      gridwidth: 1,
      zerolinecolor: "rgb(156, 155, 155)",
      zerolinewidth: 2,
    },
    // margin: {
    //   l: 40,
    //   r: 30,
    //   b: 80,
    //   t: 100,
    // },
    paper_bgcolor: "rgb(243, 243, 243)",
    plot_bgcolor: "rgb(243, 243, 243)",
    showlegend: false,
    width: "100%",
    height: "100%",
  };


  return (
    <div>
      <Header />
      <div className="results-container">
        <div className="content">
          <h1>
            Tumor Heterogeneity Results <strong>{cancerSite}</strong>
          </h1>
          {cancerSite ? (
            <>
              <div className="plot-container" style={{width:'80vw'}}>
                <div className="boxenplot" >
                  <div>
                    <Plot data={data} layout={layout} />
                  </div>
                </div>
              </div>
              <ThTable depth_json={depth_scores} site={cancerSite} />
              <div className="plot-container" style={{width:'80vw'}}>
                <div className="boxenplot" >
                  <div>
                  <SampleTHPlot data={samples_th} />
                  </div>
                </div>
              </div>
              <div style={{ margin: "20px", padding: "20px" }}>
              <h2 style={{ textAlign: "center" }}> Noisy Genes and Enrichment</h2>
              <TumorNoisyGenes data={noisy_genes_data} site={cancerSite} />
            </div>
              </>
          ) : (
            <p>No cancer site selected. Please go back and select one.</p>
          )}
        </div>
      </div>
      <ResultsFooter />
    </div>
  );
};

export default TumorHeterogeneityResults;
