import { useState } from "react";
import DataTable from "../components/DataTable";
import getDisplayField from "../utils/getDisplayField";
import Header from "../components/Header";
import TabsBar from "../components/v2/TabsBar";
import ResultOptionsBar from "../components/v2/ResultOptionsBar";
import Heatmap from "../components/Heatmap";
import { useSelector } from "react-redux";
import generateHeatmapData from "../utils/generateHeatmapData";
import Footer from "../components/v2/Footer";
import ResultsFooter from "../components/v2/ResultsFooter";
import ResultsTable from "../components/ResultsTable";
import IndepCancerSelect from "../components/v2/IndepCancerSelect";
import CancerSelection from "../components/v2/CancerSelection";
// import SampleTable from "../components/v2/SampleTable";
import noiseDiff from "../utils/noiseDiff";
import NoiseDiffAnalysis from "../components/NoiseDiffAnalysis";
import PathwayGeneAnalysis from "../components/PathwayGeneAnalysis";
import NoiseScatterPlot from "../components/NoiseScatterPlot";
import SampleChart from "../components/SampleChart";
import BoxenPlot from "../components/BoxenPlot";
import BarPlot from "../components/BarPlot";

function ResultsPage2() {
  const [unit, setUnit] = useState("tpm");
  const [noiseMethod, setNoiseMethod] = useState("cv");
  let displayFieldNoise = getDisplayField("noise", unit);
  let displayFieldExp = getDisplayField("exp", unit);

  let geneNames = useSelector((state) => state.data.currentFilters.geneNames);
  let geneIDs = useSelector((state) => state.data.currentFilters.geneIDs);
  let docs = useSelector((state) => state.data.docs);
  let cancerFilters = useSelector((state) => state.data.currentFilters.cancers);
  let queryType = useSelector((state) => state.data.geneQueryType);
  let queryContent = useSelector((state) => state.data.geneQueryContent);
  let notFoundGenes = useSelector((state) => state.data.notFoundGenes);
  
  let filterDocNames = cancerFilters.map((c) => c.toLowerCase());
  if (docs && filterDocNames.length != 0)
    docs = docs.filter((doc) =>
      filterDocNames.includes(doc.cancer_type.split(" ")[0])
    );
  console.log(docs);

  let cancers = docs.map((doc) => doc.cancer_type); // remove later
  let dataMatrixNoise = generateHeatmapData(
    docs,
    displayFieldNoise,
    geneIDs,
    noiseMethod,
    false
  );
  let dataMatrixExp = generateHeatmapData(docs, displayFieldExp, geneIDs, "", false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (queryType !== "Enrichment Analysis" && queryType != "Database ID")
    queryContent = queryContent.join(", ");

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="page">
      <Header />
      <TabsBar tab="Results" />
      <div
        className="query-header"
        style={{ padding: "10px", paddingLeft: "20px" }}
      >
        <p> Results </p>
      </div>
      <div className="result-page-body">
        <div className="filter-panel-container results">
          <div className="query-info">
            <p className="query-type">Pathway Query</p>
            <p className="query-type-name">{queryType}</p>
            {queryType !== "Enrichment Analysis" ? (
              queryType != "Database ID" ? (
                <p className="query-content">
                  {showFullText
                    ? queryContent
                    : `${queryContent.slice(0, 36)}${
                        queryContent.length > 36 ? "..." : ""
                      }`}
                  {queryContent.length > 36 && (
                    <span className="more-text" onClick={toggleText}>
                      {showFullText ? " Show Less" : " Show More"}
                    </span>
                  )}
                </p>
              ) : (
                <p className="query-content">{queryContent}</p>
              )
            ) : (
              <>
                <span className="query-term">
                  <span>{queryContent.term}</span>
                  <span className="category">[{queryContent.category}]</span>
                </span>
                <span className="query-desc">{queryContent.description}</span>
              </>
            )}
          </div>
          <ResultOptionsBar
            flex="col"
            unit={unit}
            setUnit={setUnit}
            noise={noiseMethod}
            setNoise={setNoiseMethod}
          />
          <IndepCancerSelect notPopup={true} />
        </div>
        {geneNames.length > 0 ? (
          <div className="data">
            {/* <div className="counts">
              <SampleTable dataDocs={docs} />
            </div> */}
            <div>
              <SampleChart dataDocs={docs} />
            </div>
            <div className="charts">
              <Heatmap
                displayField={displayFieldExp}
                cancers={cancers}
                genes={geneNames}
                dataMatrix={dataMatrixExp}
              />
              <Heatmap
                displayField={displayFieldNoise}
                cancers={cancers}
                genes={geneNames}
                dataMatrix={dataMatrixNoise}
                noiseMethod={noiseMethod}
              />
            </div>
            <PathwayGeneAnalysis docs={docs} />
            <div className="table-container">
              <ResultsTable displayField={displayFieldExp} noiseMethod="" />
              <ResultsTable
                displayField={displayFieldNoise}
                noiseMethod={noiseMethod}
              />
            </div>
            <NoiseDiffAnalysis docs={docs} displayField={displayFieldExp} />
            <NoiseScatterPlot />
          </div>
        ) : (
          <h4 className="no-results">No Results Found for Input.</h4>
        )}
      </div>
      <ResultsFooter />
    </div>
  );
}

export default ResultsPage2;
