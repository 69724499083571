import { createSlice, current } from "@reduxjs/toolkit";
import { initGeneIDs, initGeneNames } from "../data/genes";
import Cookies from 'js-cookie';

const initialState = {
    docs: [],
    notFoundGenes : [],
    currentFilters: {
        cancers: [],
        geneIDs: [],
        geneNames: [],
    },
    p_tith: {},
    p_tith_excluded: [],
    geneQueryType: '',
    geneQueryContent: '',
    data: {}
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        saveInitData : (state, action) => {
            //console.log("action received: saveInitData");
            const newstate = {
                docs: action.payload,
                currentFilters: {
                    cancers: [], // no filter
                    geneIDs: initGeneIDs,
                    geneNames: initGeneNames,
                }
            };
            //console.log("new state: ", newstate);
            return newstate;
        },
        saveFilterData : (state, action) => {
            //console.log("action received: saveFilterData");
            const {filterDocs, currentFilters, queryType, queryContent, notFoundGenes, p_tith, p_tith_excluded} = action.payload;
            const newstate = {
                ...state,
                docs: filterDocs,
                currentFilters: { 
                    ...state.currentFilters,
                    geneIDs: currentFilters.geneIDs,
                    geneNames: currentFilters.geneNames,
                },
                p_tith: p_tith,
                p_tith_excluded: p_tith_excluded,
                geneQueryType: queryType,
                geneQueryContent: queryContent,
                notFoundGenes
            };
            //console.log("new state: ", newstate);
            return newstate;
        },
        applyCancerFilter : (state, action) => {
            //console.log("action recieved: applyCancerFilter");
            const newstate = {
                ...state,
                currentFilters: {
                    ...state.currentFilters,
                    cancers: action.payload
                }
            }
            Cookies.set('cancers', action.payload, { expires: 7 });
            //console.log("new state: ", newstate);
            return newstate;
        },
        saveTHdata : (state, action) => {
            const newstate = {
                ...state,
                data: action.payload
            }
            return newstate;
        }   
    }
})

export default dataSlice.reducer;
export const {saveInitData, saveFilterData, applyCancerFilter, saveTHdata} = dataSlice.actions;