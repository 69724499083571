import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/v2/Footer";
import { cancers } from "../data/cancers";
import { saveTHdata } from "../store/dataSlice";
import { useDispatch } from "react-redux";

const TumorHeterogeneity = () => {
  const [filteredSites, setfilteredSites] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setFocused] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    const matches = cancers.filter((cancer) =>
      cancer.toLowerCase().startsWith(query.toLowerCase())
    );

    setfilteredSites(matches.slice(0, 20));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!document.querySelector(".suggestions:hover")) {
        setFocused(false);
      }
    }, 100);
  };

  const handleNavigation = (cancer) => {
    if (cancer === "") {
      navigate("/");
    } else {
      //const url = "http://localhost:8000/th?site=" + cancer;
      const url = "https://tcgna.cancerlogics.com/th?site=" + cancer;
      fetch(url)
      .then(data => data.json())
      .then(json => {
          console.log("res: ", json);
          dispatch(saveTHdata(json));
          navigate("/tumor-heterogeneity-results", {
            state: { cancerSite: cancer },
          });
      })     
    }
  };

  return (
    <>
      <Header />
      <div className="heterogeneity-analysis">
        <div className="box sites-box">
          <div
            className="input-box sites"
            style={{ textAlign: "center", fontSize: ".95rem" }}
          >
            Analyse a selected cancer site's noise and tumor values across
            different metrics. Currently built on GDC datasets.
          </div>
        </div>
        <div className="heterogeneity-analysis__main">
          <h2>Tumor Heterogeneity Selection</h2>
          <h3 className="selection-subtitle">Cancer Site</h3>

          <p className="description">
            Search by gene name/symbol. Separate symbols by commas. Select the
            result of interest.
          </p>
          <div>
            <input
              className="heterogeneity-analysis__search-box"
              placeholder="e.g: bladder or brain"
              value={searchTerm}
              onChange={handleSearch}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            {searchTerm && filteredSites.length > 0 && (
              <ul className={`suggestions ${isFocused ? "" : "hide"}`}>
                {filteredSites.map((gene, index) => (
                  <li
                    style={{ textAlign: "left" }}
                    key={index}
                    className="suggestion-item"
                  >
                    <button onClick={() => handleNavigation(gene)}>
                      {gene}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <button
              onClick={() => handleNavigation("")}
              className="back-button"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TumorHeterogeneity;
