import AutocompleteGN from "../components/v2/AutocompleteGN";
import { useState } from "react";
import IndepCancerSelect from "../components/v2/IndepCancerSelect";
import CircularProgress from '@mui/material/CircularProgress';

function GNEnrich ({cancers}) {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const togglePopup = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
                <div className="filter-header">
                <h3 className="selection-title">Pathway Selection</h3>
                <h3 className="selection-subtitle">Encrichment</h3>
                </div>
                <div className="box sites-box">
                    <div className="text-content tsites">
                        <h2 className="title">Sites Selected: </h2>
                    </div> 
                    <strong className="input-box sites" >{cancers.length > 0 ? cancers.join(', ') : 'None'}</strong>
                </div>
                <div className="box">
                    <div className="text-content">
                        <h2 className="title">Gene Enrichment</h2>
                        <p className="description">Search by gene name/symbol. Separate symbols by commas. Select the result of interest.</p>
                    </div>
                    <AutocompleteGN setLoading={setLoading} cancers={cancers} setErrMsg={setErrMsg} />
                </div>
                {errMsg && <p style={{color:"red"}}>{errMsg}</p>}
                {isOpen && (
                <div className="popup-overlay" onClick={togglePopup}>
                <div className="popup-content" onClick={e => e.stopPropagation()}>
                   <IndepCancerSelect onApply={togglePopup} />
                </div>
                </div>
            )}
            {loading && <div className="loading"> <CircularProgress /> </div>}
        </>
    )
}

export default GNEnrich;