import React, { useState } from "react";
import Plot from "react-plotly.js";
import toTitleCase from "../utils/toTitleCase";
import { Button } from "@mui/material";

function SampleChart({ dataDocs }) {
  const [showTumorSamples, setShowTumorSamples] = useState(false);

  // get normal and tumor data
  const normalData = dataDocs.filter((doc) =>
    doc.cancer_type.includes("normal")
  );
  const tumorData = dataDocs.filter((doc) => doc.cancer_type.includes("tumor"));

  const cancerSites = normalData.map((doc) =>
    toTitleCase(doc.cancer_type.replace(" normal", ""))
  );

  // Check if only two cancer sites are selected
  const isTwoSites = normalData.length <= 2 && tumorData.length <= 2;

  let plotData;

  if (isTwoSites) {
    // If two sites, then for each site show both tumor and normal sample counts grouped together
    plotData = [
      {
        x: cancerSites,
        y: normalData.map((doc) => doc.num_samples),
        type: "bar",
        name: "Normal Samples",
        marker: { color: "blue" },
      },
      {
        x: cancerSites,
        y: tumorData.map((doc) => doc.num_samples),
        type: "bar",
        name: "Tumor Samples",
        marker: { color: "red" },
      },
    ];
  } else {
    // More than two sites: toggle between tumor and normal
    plotData = [
      {
        x: cancerSites,
        y: showTumorSamples
          ? tumorData.map((doc) => doc.num_samples)
          : normalData.map((doc) => doc.num_samples),
        type: "bar",
        marker: { color: showTumorSamples ? "red" : "blue" },
        name: showTumorSamples ? "Tumor Samples" : "Normal Samples",
      },
    ];
  }

  return (
    <div style={{ textAlign: "right", margin: "30px auto" }}>
      {!isTwoSites && (
        <Button
          variant="contained"
          onClick={() => setShowTumorSamples(!showTumorSamples)}
          style={{
            fontSize: "0.8rem",
            marginRight: "100px",
            marginBottom: "20px",
            padding: "5px 10px",
            textTransform: "capitalize",
          }}
        >
          {showTumorSamples ? "Show Normal Samples" : "Show Tumor Samples"}
        </Button>
      )}
      <Plot
        data={plotData}
        layout={{
          barmode: isTwoSites ? "group" : "overlay",
          title: isTwoSites
            ? "Counts for Tumor and Normal Samples by Cancer Type"
            : "Sample Counts by Cancer Type",
          xaxis: { title: "Cancer Type" },
          yaxis: { title: "Number of Samples" },
          margin: { t: 50, b: 100, l: 50, r: 50 },
          showlegend: true,
        }}
        style={{ width: "80%", margin: "0 auto" }}
      />
    </div>
  );
}

export default SampleChart;
