export default function generateCSV_TH(depth_json) {
    const header = Object.keys(depth_json);
    let csv = [];
    csv.push(depth_json.Sample);
    csv.push(depth_json.tpm);
    csv.push(depth_json.fpkm);
    csv.push(depth_json.fpkm_uq);
    const csvData = csv[0].map((_, colIndex) => csv.map(row => row[colIndex]));
    csvData.unshift(header);
    return csvData;
}