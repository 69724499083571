import { useNavigate } from "react-router-dom";
import sitelogo from '../site_logo.png';
import hamburger from '../ham_menu.png';

function Header() {
  const menuClick = () => {
    const dropdown = document.querySelector(".header__hamburger-menu__dropdown");

    if (dropdown.classList.contains("header__hamburger-menu__dropdown--hidden")) {
      dropdown.classList.remove("header__hamburger-menu__dropdown--hidden");
    } else {
      dropdown.classList.add("header__hamburger-menu__dropdown--hidden");
    }
  }

  return (
    <header className="header">
      <div className="header__main">
        <div className="header__logo-cont">
          <a href="#">
            <img className="header__logo" src={sitelogo} />
          </a>
        </div>

        <div className="header__hamburger-menu" onClick={menuClick}>
          <img src={hamburger} />

          <div className="header__hamburger-menu__dropdown header__hamburger-menu__dropdown--hidden">
            <a href="#">Home</a>
            <a href="#">Pathway Analysis</a>
            <a href="#">Gene Analysis</a>
            <a href="#">Tumor Analysis</a>
          </div>
        </div>

        <div className="header__links-wrapper">
          <a href="#">Home</a>
          <a href="#">Pathway Analysis</a>
          <a href="#">Gene Analysis</a>
          <a href="#">Tumor Analysis</a>
        </div>
      </div>
    </header>
  );
}

export default Header;