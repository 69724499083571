import { useSelector } from "react-redux";
import { autocomplete_url, specie_suffix } from "../data/urls";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import toTitleCase from "../utils/toTitleCase";

export default function NoiseAnalyse({diff_genes, site, global_thres }) {
  const [enrich, setEnrich] = useState(null);
  const[local_thres, setLocal_thres] = useState(null);
  
  const handleChange = (e) => {
    const newValue = e.target.value === '' ? '' : Number(e.target.value);
    setLocal_thres(newValue);
  };

  let displayGenes = diff_genes.filter((r) => (global_thres > 0) ? r.val >= global_thres : r.val <= global_thres);

  if (local_thres != global_thres)
    displayGenes = diff_genes.filter((r) => (local_thres > 0) ? r.val >= local_thres : r.val <= local_thres);

  let url = autocomplete_url + displayGenes.join("%0d") + specie_suffix;

  useEffect(() => {
    setLocal_thres(global_thres);
  }, [global_thres]); 
  
  useEffect(() => {
    let ignore = false;
    if (displayGenes.length > 2) {
      fetch(url)
        .then((data) => data.json())
        .then((json) => {
          console.log("enrich: ", json)
          if (!ignore) {
            let topEntries = [];
            let counter = 0;
            let currentCategory = null;

            for (const entry of json) {
              if (entry.category !== currentCategory) {
                currentCategory = entry.category;
                counter = 0;
              }
              if (counter < 2) {
                counter++;
                topEntries.push(entry);
              }
            }
            setEnrich(topEntries);
          }
        })
        .catch((err) => setEnrich([]));
    }
    return () => {
      ignore = true;
    };
  }, [site]);

  return (
    <div className="gene-analysis-container">
    <Box sx={{ position: "relative", padding: "30px 10px 20px 10px" }}>
      <h4 className="site-name">{toTitleCase(site)}</h4>
      <div className="content-container">

      <div className="threshold-container">
        <label>
          logFC: 
          <input 
            type="number" 
            value={local_thres} 
            onChange={handleChange} 
            step="0.1" 
            className="threshold-input"
          />
        </label>
      </div>

      <div className="genes-container">
        <div className="genes-table">
          <div className="table-header">
            <div><strong>Gene</strong></div>
            <div><strong>logFC</strong></div>
          </div>
          <div className="genes-list">
            {displayGenes.map((gene) => (
              <div className="gene-row" key={gene.name}>
                <div className="gene-name">{gene.name}</div>
                <div className="gene-logfc">{gene.val.toFixed(2)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
{/*       
    // <div>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       flexDirection: "column",
    //       gap: "10px",
    //       borderRadius: 2,
    //       backgroundColor: "lightblue",
    //       maxWidth: "20rem",
    //       padding: "30px 10px 20px 10px",
    //       maxHeight: "15rem",
    //       overflowY: "auto",
    //     }}
    //   >
    //     <h4 className="noise-analyse cancer-site-name">{toTitleCase(site)}</h4>
    //     <h5 className="noise-analyse h5">Genes wth Differential Noise</h5>
    //     <label>logFC threshold: <input type="number" value={local_thres} onChange={handleChange} step="0.1" style={{width:'50px'}} /></label>
    //     <div>
    //     <div className="noise-analyse-table"><div className="noise-analyse gene"><strong>Gene</strong></div><div><strong>logFC</strong></div></div> 
    //       {displayGenes.map((gene) => (
    //         <div className="noise-analyse-table"><div className="noise-analyse gene">{gene.name}</div><div>{gene.val}</div></div> 
    //       ))}
    //     </div> */}
        {enrich && enrich.length > 0 && (
          <div>
            <h5 className="noise-analyse h5">Enrichment</h5>
            <ul className="noise-analyse ul">
              {enrich.map((option) => (
                <li
                  key={option.term}
                  className="noise-analyse enrichment-option"
                >
                  <div className="noise-analyse enrichment-term">
                    {option.term}&nbsp;&nbsp;&nbsp;&nbsp;[{option.category}]
                  </div>
                  <div className="noise-analyse enrichment-description">
                    {option.description}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        </div>
      </Box>
    </div>
  );
}
