import Enrichment from './Enrichment';
import HorizontalBarChart from './HorizontalBarChart';
import { CSVLink } from "react-csv";

export default function TumorNoisyGenes ({data, site}) {
    let all_data = [];

    let keys = data.diff_noisy_genes
    let values = data.diff_noisy_vals
    let dict = Object.fromEntries(keys.map((key, index) => [key, Math.log2(values[index])]));
    const chart1 = <HorizontalBarChart data={dict} title={'Genes with Highest Differential Noise'} xlabel={'logFC'} ylabel={'Gene'} />;
    all_data.push(keys);
    all_data.push(values);

    keys = data.noisy_normal_genes
    values = data.noisy_normal_vals
    dict = Object.fromEntries(keys.map((key, index) => [key, values[index]]));
    const chart2 = <HorizontalBarChart data={dict} title={'Genes with Highest Normal State Noise'} xlabel={'Noise (CV)'} ylabel={'Gene'} />;
    all_data.push(keys);
    all_data.push(values);

    keys = data.noisy_tumor_genes
    values = data.noisy_tumor_vals
    dict = Object.fromEntries(keys.map((key, index) => [key, values[index]]));
    const chart3 = <HorizontalBarChart data={dict} title={'Genes with Highest Tumor State Noise'} xlabel={'Noise (CV)'} ylabel={'Gene'} />;
    all_data.push(keys);
    all_data.push(values);

    let csvData = all_data[0].map((_, colIndex) => all_data.map(row => row[colIndex]));
    csvData.unshift(['diff_noisy_genes', 'logFC', 'noisy_genes_normal', 'cv_normal', 'noisy_genes_tumor', 'cv_tumor']);

    return (
        <>
        {chart1}
        <Enrichment genes={data.diff_noisy_genes} />
        {chart2}
        <Enrichment genes={data.noisy_normal_genes} />
        {chart3}
        <Enrichment genes={data.noisy_tumor_genes} />
        <button className="analysis-button download"><CSVLink data={csvData} filename={'noisy_genes_' + site + '.csv'} className="link">Download Noisy Genes Data</CSVLink></button>
        </>
    );
}