import "./App.css";
import "./sass/App.css";
import GNEnrich from "./pages/GNEnrich";
import QueryPage from "./pages/QueryPage";
import GNNeighbor from "./pages/GNNeighbor";
import GNdb from "./pages/GNdb";
import GFile from "./pages/GFile";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import ResultsPage2 from "./pages/ResultsPage2";
import GeneAnalysis from "./pages/GeneAnalysis";
import HomePage from "./pages/HomePage";
import GeneAnalysisResult from "./pages/GeneAnalysisResult";
import TumorHeterogeneity from "./pages/TumorHeterogeneity";
import TumorHeterogeneityResults from "./pages/TumorHeterogeneityResults";

function App() {
  const docs = useSelector((state) => state.data.docs);
  let cancers = useSelector((state) => state.data.currentFilters.cancers);
  const results = docs.length > 0 ? true : false;
  const cancerFiltered = cancers.length > 0 ? true : false;

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="gene-analysis" element={<GeneAnalysis />} />
      <Route path="tumor-heterogeneity" element={<TumorHeterogeneity />} />
      <Route
        path="tumor-heterogeneity-results"
        element={<TumorHeterogeneityResults />}
      />
      <Route
        path="gene-analysis-results"
        element={results ? <GeneAnalysisResult /> : <Navigate to="/" />}
      />
      <Route
        path="results"
        element={results ? <ResultsPage2 /> : <Navigate to="/" />}
      />
      <Route path="query" element={<QueryPage />} />
      <Route path="query/network-enrich" element={<GNEnrich />} />
      <Route
        path="query/network-nearest-neighbor"
        element={cancerFiltered ? <GNNeighbor /> : <Navigate to="/query" />}
      />
      <Route
        path="query/network-db"
        element={cancerFiltered ? <GNdb /> : <Navigate to="/query" />}
      />
      <Route
        path="query/gene-file"
        element={cancerFiltered ? <GFile /> : <Navigate to="/query" />}
      />
    </Routes>
  );
}

export default App;
