export default function generateCSVData(displayField, docs, geneIDs, geneNames, noiseMethod, appendMean) {
    console.log("displayField:", displayField);
console.log("docs:", docs);
console.log("geneIDs:", geneIDs);
console.log("geneNames:", geneNames);
console.log("noiseMethod:", noiseMethod);
console.log("appendMean:", appendMean);

    const header1 = [ 'geneName', 'geneID'];
    const header2 = docs.map(doc => doc.cancer_type);
    const header = [...header1, ...header2];
    let csv = [];
    csv.push(header);
    for (let i=0; i<geneIDs.length; i++){
        let row = [geneNames[i], geneIDs[i].split('-').join('.')];
        for (let doc of docs){
            let x = doc[displayField][geneIDs[i]].value;
            if (noiseMethod == 'cvs')
                x *= x;
            row.push(x.toString());
        }
        csv.push(row);
    }

    if (appendMean == true){
        let meanRow = ['Average', '-'];
        for (let col = 2; col < header.length; col++) {
            let colValues = csv.slice(1).map(row => parseFloat(row[col])); // Skip the header
            let mean = colValues.reduce((sum, val) => sum + val, 0) / colValues.length;
            meanRow.push(mean.toString());
        }
        csv.push(meanRow);
    }

    console.log("csvdata: ", csv);
    
    return csv;
}