import { create, all } from 'mathjs'

export default function generateHeatmapData(cancerDocs, displayField, geneIDs, noiseMethod, appendMean){
    console.log("show avf in heatmap: ", appendMean)
    const math = create(all,  {})
    let gene_wise_data = {};
    geneIDs.forEach(id => {gene_wise_data[id] = []});
    for (let doc of cancerDocs){
        console.log("site in heatmap: ", doc.cancer_type)
        for (let i=0; i<geneIDs.length; i++){
            let x = doc[displayField][geneIDs[i]].value;
            if (noiseMethod == 'cvs')
                x *= x;
            gene_wise_data[geneIDs[i]].push(x);
        }
    }

    let heatmapdata = Object.values(gene_wise_data);
    console.log("gene order in heatmap: ", Object.keys(gene_wise_data))
    console.log("before: ", heatmapdata)
    if (appendMean){
    // Calculate column-wise means and append as a new row
    const numRows = heatmapdata.length;
    const numCols = heatmapdata[0]?.length || 0; // Check if there are columns

    if (numCols > 0) {
        let colMeans = [];
        for (let col = 0; col < numCols; col++) {
            let colValues = heatmapdata.map(row => row[col]);
            colMeans.push(math.mean(colValues));
        }
        heatmapdata.push(colMeans); // Add the mean row before normalizing
    }
    console.log("after: ", heatmapdata)
    }
    //console.log("unnormalised gene wise: ", Object.values(gene_wise_data));
    let normalisedData = [];
    for (let i=0; i<heatmapdata.length; i++){
        if (heatmapdata[i].length > 0){
        let mean = math.mean(heatmapdata[i]);
        let std = math.std(heatmapdata[i]);
        //console.log("mean: ", mean, "std: ", std);
        if (std == 0){
            normalisedData.push(heatmapdata[i]);
        }
        else{
            let normalised = heatmapdata[i].map(x => (x-mean)/std);
            normalisedData.push(normalised);
        }
        }
    }
    console.log("normalised data: ", normalisedData);
    return normalisedData;
}