import { create, all } from 'mathjs'
const math = create(all)

export default function getNoiseVariance(cancerDocs){
    // for each site
    // for each of the 3 noise fields (3 units)
    // get the value in each key (gene)
    // mean them, dev them
    const fields = ['noise_tpm', 'noise_fpkm', 'noise_fpkm_uq'];
    const nv = {}
    for (const doc of cancerDocs){
        let site = doc.cancer_type;
        nv[site] = {}
        for (const field of fields){
            let vals = []
            for (const data of Object.values(doc[field]))
                vals.push(data.value)
            let mean = math.mean(vals)
            let stdDev = math.std(vals)
            const cv = mean == 0 ? 0 : (stdDev/mean) 
            nv[site][field.substring(6)] = cv
       }
    }
    return nv;
}

