import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function TabsBar({ tab }) {
  const docs = useSelector((state) => state.data.docs);
  const navigate = useNavigate();

  const onSwitch = (target) => {
    if (target === "Results" && docs.length > 0) navigate("/results");
    else if (target === "Query") navigate("/query");
    else if (target === "home") navigate("/");
  };

  return (
    <div className="option-bar">
      <div className={`option unselected`} onClick={() => onSwitch("home")}>
        Home
      </div>
      <div
        className={`option ${tab === "Query" ? "selected" : "unselected"}`}
        onClick={() => onSwitch("Query")}
      >
        Query
      </div>
      <div
        className={`option ${tab === "Results" ? "selected" : "unselected"}`}
        onClick={() => onSwitch("Results")}
      >
        Results
      </div>
    </div>
  );
}

export default TabsBar;
