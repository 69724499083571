import { useSelector } from "react-redux";
import getGenesLogFC from "../utils/getDiffNoisyGenes";
import NoiseAnalyse from "./NoiseAnalyse";
import { useState } from "react";

export default function NoiseDiffAnalysis({ docs, displayField }) {
  let sorted_docs = docs.sort((d1, d2) => d1.cancer_type > d2.cancer_type);
  let geneNames = useSelector((state) => state.data.currentFilters.geneNames);
  let geneIDs = useSelector((state) => state.data.currentFilters.geneIDs);
    
  const [logFC_thres, setlogFC_thres] = useState(0.7);

  let diff_noisy_genes = {};
  sorted_docs.forEach((d,i) => {
    if (i%2 == 0){
    let normal= sorted_docs[i][displayField];
    let tumor = sorted_docs[i + 1][displayField];
    let diff_genes = getGenesLogFC(normal, tumor, geneIDs, geneNames);
    let site = sorted_docs[i].cancer_type.split(' ').slice(0, -1).join(' ');
    diff_noisy_genes[site] = diff_genes;
  }
  })
  console.log(diff_noisy_genes)


  return (
    <div className="noise-diff-analysis">
      <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
        Differential Noise Analysis
      </h3>
      <div className="threshold-selector" >
        <label htmlFor="logFC_thres" className='p-title' style={{display:'inline-flex'}}> Set logFC Threshold For All Sites: </label>
        <input type="range" min={-5} max={5} step={0.1} className="numeric-input" value={logFC_thres} onChange={e => setlogFC_thres(e.target.value)} style={{width: '80px', marginLeft: '5px' }}  />
        <span className="current-label diff">{logFC_thres}</span>
      </div>
      <div className="noise-analysis-grid">
        {sorted_docs.map((d, i) => {
          let site = sorted_docs[i].cancer_type.split(' ').slice(0, -1).join(' ');
          if (i % 2 === 0)
            return (
              <NoiseAnalyse
                key={site}
                diff_genes={diff_noisy_genes[site]}
                site={site}
                global_thres={logFC_thres}
              />
            );
        })}
      </div>
    </div>
  );
}
