import { cancers } from "../../data/cancers";
import { useState} from "react";

function CancerSelection({selectedCancers, setSelectCancers}){
    const [errMsg, setErrMsg] = useState("");
    const [select, setSelect] = useState(true);

    const handleSelectCancer = (option) => {
        let newSelection;
        if (option == "all"){
            newSelection = {...selectedCancers};
            for (let cancer in selectedCancers)
                newSelection[cancer] = true;
        }
        else if (option == "none"){
            newSelection = {...selectedCancers};
            for (let cancer in selectedCancers)
                newSelection[cancer] = false;
        }
        else {
            newSelection = {
                ...selectedCancers,
                [option]: !selectedCancers[option],
            };
        }
        setSelectCancers(newSelection);
        if (errMsg)
            setErrMsg("");
    };

    return (
        <div className="filter-panel-container">
            <div className="filter-group">
            <div className="filter-header">
                    <h3 className="selection-title" > Primary Sites </h3>
            </div>
                <div className="filter-buttons">
                    <button onClick={() => handleSelectCancer("all")}> Select All</button>
                    <button onClick={() => handleSelectCancer("none")}> Deselect All</button>
                </div>
                <div className="filter-options-container">
                {cancers.map(cancer => 
                <label htmlFor={cancer+"cancer"} key={cancer+"cancer"} className={selectedCancers[cancer] ? "checked" : ""} ><input id={cancer+"cancer"} type="checkbox" onChange={() => handleSelectCancer(cancer)} checked={selectedCancers[cancer]} /> {cancer}</label>
                )}
                </div>
            </div>
            {errMsg && <p className="err">{errMsg}</p>}
        </div>
    )
}

export default CancerSelection;