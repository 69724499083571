import React from "react";
import Header from "../components/Header";
import Footer from "../components/v2/Footer";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigation = (target) => {
    if (target === "pathway-analysis") navigate("/query");
    else if (target === "gene-analysis") navigate("/gene-analysis");
    else if (target === "tumor-heterogeneity") navigate("/tumor-heterogeneity");
  };
  return (
    <div>
      <Header />
      <div className="home">
        <h2>The Cancer Noise Atlas</h2>
        <p>
          The Noise Atlas (2024) is a web tool to analyse gene and pathway noise
          in bulk RNA-seq datasets from The Genomic Data Commons (GDC Data Portal). The
          Noise Atlas database contains noise metrics computed for genes across
          all currently available sites in both tumor and normal states, which
          are used to compute pathway noise metrics at runtime for a pathway
          selected via a STRINGdb enrichment, STRINGdb nearest neighbours query,
          a direct database ID, or a user uploaded gene list. Visualize noise
          analyses and tweak available parameters to observe changes.
        </p>
        <div className="buttons">
          <div>
          <button onClick={() => handleNavigation("gene-analysis")}>
              Gene Analysis
            </button>
          </div>
          <div>
          <button onClick={() => handleNavigation("pathway-analysis")}>
              Pathway Analysis
            </button>
            <button onClick={() => handleNavigation("tumor-heterogeneity")}>
              Tumor Analysis
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
